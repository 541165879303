import mock from '../mock'

const data = {
  // blog list
  blogList: [
    {
       id:"0",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/SLgNEWcexUtvqRKb.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/le-quit-good",
       title:"Le Quit Good !",
       excerpt:"Le Quit Good !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"1",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/KrXEqLInfodCUUmk.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/hello-world-zwrze",
       title:"L'art de la relance client !",
       excerpt:"L'art de la relance client !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"2",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/wdPgDJCOHleNGooS.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/hello-world-rcxwz",
       title:"Never give up !",
       excerpt:"Never give up !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"3",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/usWWoYdByNrVnzYA.png?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/hello-world-lyuww",
       title:"Sherlock arrive très prochainement !",
       excerpt:"Sherlock arrive très prochainement !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"4",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/ESYzjTfVIAKfXaGC.png?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/personal-timeline",
       title:"Personal Timeline",
       excerpt:"Personal Timeline",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"5",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/LNiNxLZuddmKuFHt.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/a-quoi-sert-un-diplome",
       title:"À quoi sert un diplôme ?",
       excerpt:"À quoi sert un diplôme ?",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"6",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/COvbDikpHeeNxAgs.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/temps",
       title:"Le temps et l'entrepreneur !",
       excerpt:"Le temps et l'entrepreneur !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"7",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/ctMrsaPtvCHRbOkR.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/hello-world-kqgxy",
       title:"Do you want to know more about our vision ?",
       excerpt:"Do you want to know more about our vision ?",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"8",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/XeIlNSlEkVpBcrYU.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/story-telling",
       title:"KWIKS Story Telling",
       excerpt:"KWIKS Story Telling",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    },
    {
       id:"9",
       img:"https://landen.imgix.net/blog_dojYLAREXbxNmWOG/assets/biIVjuuLozqfaVbu.jpg?w=880",
       link:"https://www.kwiksourcing.com/SharingIsKaring/a",
       title:"La HR Tech rassemble toutes les Techs !",
       excerpt:"La HR Tech rassemble toutes les Techs !",
       avatar:"/static/media/avatar-s-7.ba3f6823.jpg",
       userFullName:"kwiksourcing",
       blogPosted:"Jan 10, 2022",
       tags:[],
       comment:0
    }
 ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        img: require('@src/assets/images/banner/banner-22.jpg').default,
        title: 'Why Should Forget Facebook?',
        id: 7,
        createdTime: 'Jan 14 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-27.jpg').default,
        title: 'Publish your passions, your way',
        id: 8,
        createdTime: 'Mar 04 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-39.jpg').default,
        title: 'The Best Ways to Retain More',
        id: 9,
        createdTime: 'Feb 18 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-35.jpg').default,
        title: 'Share a Shocking Fact or Statistic',
        id: 10,
        createdTime: 'Oct 08 2020'
      }
    ],
    categories: [
      { category: 'Fashion', icon: 'Watch' },
      { category: 'Food', icon: 'ShoppingCart' },
      { category: 'Gaming', icon: 'Command' },
      { category: 'Quote', icon: 'Hash' },
      { category: 'Video', icon: 'Video' }
    ]
  },

  // detail
  blogDetail: {
    blog: {
      img: require('@src/assets/images/banner/banner-12.jpg').default,
      title: 'The Best Features Coming to iOS and Web design',
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      createdTime: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      content:
        '<p class="card-text mb-2">Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different blog posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single blog post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.</p><h4>Unprecedented Challenge</h4><ul><li>Preliminary thinking systems</li><li>Bandwidth efficient</li><li>Green space</li><li>Social impact</li><li>Thought partnership</li><li>Fully ethical life</li></ul>',
      comments: 19100,
      bookmarked: 139
    },
    comments: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander',
        commentedAt: 'May 24, 2020',
        commentText:
          'A variation on the question technique above, the multiple-choice question great way to engage your reader.'
      }
    ]
  },

  // edit
  blogEdit: {
    avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
    userFullName: 'Chad Alexander',
    createdTime: 'May 24, 2020',
    blogTitle: 'The Best Features Coming to iOS and Web design',
    blogCategories: [
      { value: 'fashion', label: 'Fashion' },
      { value: 'gaming', label: 'Gaming' }
    ],
    slug: 'the-best-features-coming-to-ios-and-web-design',
    status: 'Published',
    excerpt:
      '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
    featuredImage: require('@src/assets/images/slider/03.jpg').default
  }
}

mock.onGet('/blog/list/data').reply(() => [200, data.blogList])
mock.onGet('/blog/list/data/sidebar').reply(() => [200, data.blogSidebar])
mock.onGet('/blog/list/data/detail').reply(() => [200, data.blogDetail])
mock.onGet('/blog/list/data/edit').reply(() => [200, data.blogEdit])
