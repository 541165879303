import mock from '../mock'
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'Briefcase',
      title: 'Offres et Services',
      subtitle: '',
      qandA: [
        {
          question: 'Comment ça marche ?',
          ans:
            "La Team KWIKS vous remercie pour votre confiance ! Vous trouverez ci-après un résumé du KWIKS Processus : 1- Création de Mission 2-Choix du FR 3-Choix de créneaux pour le call de Briefing et de qualification 4-Consultation du Brief et validation ou rejet 5-Facturation du Déposit 6-Réception d’une shortlist 7-Validation de candidatures 8-Choix de créneaux d’entretien(s) 9-Choix du profil confirmé 10-Assurer le démarrage du candidat 11-Facturation des KWIKS Honoraires"
        },
        {
          question: 'Que proposons-nous ?',
          ans:
            'Notre Offre Basic comprend les actions suivantes : Compte KWIKS à disposition gratuit - Brief/ Conseil en recrutement - Sourcing de candidats & validation - Market Insight - Entretien & debrief - Reporting & notation Softskills - Organisation d’entretiens et suivi - Négociation de salaires - Vous pourrez éventuellement choisir d’ajouter d’autres options tel que : Garantie de remplacement 3 mois/4mois - Test MBTI Prise de références'
        },
        {
          question: "Qu'est-ce qu'un FR ?Qu'est-ce qu'un FR ?",
          ans:
            "Nos FastRecruiters ou FRs sont des Recruteurs Experts dans le domaine qui forment notre communauté de Talent Traders, ils sont vos Chasseurs de Talents qui vous trouveront surement la perle rare"
        },
        {
          question: 'Pouvez-vous changer un FR ?',
          ans:
          "Bien sûr ! Un FR a le droit de postuler à votre mission pour pouvoir la prendre en charge, il/elle est validé(e) par un KWIKS Ambassadeur selon les missions déjà entreprise et le domaine de spécialisation. Si vous voulez changer votre FR après le Call de Qualification et de Briefing, il suffira de cliquer sur la rubrique « Changer le FR » et votre/vos missions(s) seront otcroyé(e)s à quelqu’un d’autre."
        }
      ]
    },
    // delivery
    delivery: {
      icon: 'BookOpen',
      title: 'Brief et Shortlists',
      subtitle: '',
      qandA: [
        {
          question: "Qu'est-ce qu'un Brief ?",
          ans: "Le Brief est une transcription du Call de Briefing. Votre FR ressence les informations partagées durant le call et qui ne figurent pas obligatoirement sur la fiche de poste et les transcrit dans la rubrique Brief sur la Plateforme. Une fois le Brief imputé, c’est à vous de le valider ou de le rejeter et demander des modifications."
        },
        {
          question: "Qu'est-ce qu'une Shortlist ?",
          ans: "Une Shortlist est une sélection de profils proposé par un/une FR pour une mission donnée, elle est composée de candidats préqualifiés sourcés et validés selon les prérequis du poste cités sur la fiche de poste et mentionnés dans le Brief."
        }
      ]
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'CreditCard',
      title: 'Déposit et Honoraires',
      subtitle: '',
      qandA: [
        {
          question: "Qu'est-ce qu'un Déposit ?",
          ans: "Le Déposit est une avance sur recrutement est une garantie de collaboration de votre part ainsi qu’une preuve d’engagement de votre part. Sa valeur est de 5k MAD par Mission."
        },
        {
          question: "Comment sont calculés nos honoraires ?",
          ans:"Lorsqu’il s’agit d’une Offre Basic, nous facturons un mois de salaire net. Pour tout supplément de service, des frais supplémentaires de 1500 MAD sont ajoutés comme suit : Mois de garantie : 1500 MAD/par mois - Test MBTI : 1500 MAD - Prise de référence : 1500 MAD"
        },
        {
          question: "Quand facturons-nous nos honoraires ?",
          ans:"Nous facturons nos honoraires à l’intégration du candidat validé au sein de vos locaux."
        }
      ]
    },
    // my orders
    myOrders: {
      icon: 'RefreshCcw',
      title: 'Annulation et remboursement',
      subtitle: '',
      qandA: [
        {
          question: "Modalités d'annulation",
          ans:
            "L’annulation d’une mission est possible à tout moment, il suffit de le mentionné dans la rubrique remboursement sur la plateforme"
        },
        {
          question: "Modalités de remboursement",
          ans:
          "Lorsqu’une Mission est annulée, votre préavis pourra être remboursé dans le cas où vous n’avez rencontré aucun candidat en entretien. En cas contraire, votre déposit et réutilisable pendant une durée de 3 mois"
        }
      ]
    }
  }
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
